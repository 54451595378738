#app {
  position: absolute;
  left: 0;
  top: 0;
  /* height: 100%; */
  width: 100%;
}
body {
  margin: 0;
  /* height: 100%; */
}
html {
  /* height: 100%; */
}
a:link,
a:visited {
  text-decoration: none;
  color: #39c;
  outline: 0;
}
.clearfix:before,
.clearfix:after {
  content: " ";
  display: table;
}
.clearfix:after {
  clear: both;
}
.right {
  float: right;
}
.left {
  float: left;
}

